import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.sass']
})
export class ArticlePageComponent /*implements OnInit */{

  constructor() { }

  // ngOnInit(): void {
  // }

}
