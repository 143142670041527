<nav class="breadcrumb" aria-label="breadcrumbs">
  <ul>
    <li><a title="blank" routerLink="/"></a></li>
    <li><a routerLink="/">Home</a></li>
    <li><a routerLink="/blog">Blog</a></li>
    <li class="is-active"><a href="#" aria-current="page">{{ (article | async)?.title}}</a></li>
  </ul>
</nav>
<div class="container box has-background-white-bis">


  <div class=" is-centered">
    <p class="title has-text-centered">{{ (article|async)?.title}}</p>
    <div >
      更新日:{{ (article|async)?.update_date.toDate() | date:"yyyy/MM/dd HH:mm"}}
      <!--      {{ article?.created_date.toDate() | date:"yyyy/MM/dd HH:mm" }}-->
    </div>
    <div class="block">
      作成日:{{ (article|async)?.created_date.toDate() | date:"yyyy/MM/dd HH:mm"}}
      <!--      {{ article?.created_date.toDate() | date:"yyyy/MM/dd HH:mm" }}-->
    </div>
    <div class="title has-text-left">
      <a href="https://b.hatena.ne.jp/entry/" class="hatena-bookmark-button"
         data-hatena-bookmark-layout="vertical-normal" data-hatena-bookmark-lang="ja" title="このエントリーをはてなブックマークに追加"><img
        src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png" alt="このエントリーをはてなブックマークに追加"
        width="20" height="20" style="border: none;"/></a>
    </div>
  </div>

  <div class="tile is-ancestor card-content">
    <div class="tile">
    </div>
    <div class="tile is-parent is-8">
      <div class="tile is-child">

        <div class="content is-medium">

          <markdown [data]=" (article | async)?.content " ngPreserveWhitespaces></markdown>
        </div>

        <!--追加-->
        <div *ngFor="let element of (article | async)?.elements; let i = index" class="content is-medium">
          <div *ngIf="element.type==='html'" [innerHTML]='element.safeHTML'></div>
          <markdown class="content is-medium" *ngIf="element.type==='markdown'" [data]="element.source"
                    ngPreserveWhitespaces></markdown>
        </div>

      </div>

    </div>
    <div class="tile">
    </div>
  </div>
</div>



