<!--<div class="container">-->
<!--  <div class="columns">-->
<!--    <div class="column"></div>-->
<!--  </div>-->
<!--  <div class="columns">-->
<!--    <div class="column"></div>-->
<!--    <div class="column is-half">-->
<!--      <div class="card">-->
<!--        <div class="card-content">-->
<!--          <div class="content">-->
<!--            <a routerLink="/blog">-->
<!--              blog-->
<!--            </a>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="column"></div>-->
<!--  </div>-->
<!--</div>-->

<app-article></app-article>
